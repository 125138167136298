import { createPopper } from "@popperjs/core";

const jQuery = window.jQuery;

export default {
  init() {
    let slides = jQuery(".slides li");

    for (let i = 0; i < slides.length; i++) {
      let title = jQuery(slides[i]).find(".caption-wrap .caption h3").html();
      setTimeout(function () {
        let pagers = jQuery(".flex-control-paging li");
        jQuery(pagers[i]).append(`<h5>${title}</h5>`);
      }, 100);
    }

    jQuery(".flexslider").addClass("spi-show");

    jQuery(document).on("click touchstart", ".flex-control-nav a", function () {
      console.log("click");
      jQuery(".spi-backdrop, .spi-slide-close").remove();
      jQuery(".flexslider")
        .prepend("<div class='spi-backdrop'></div>")
        .addClass("spi-show")
        .append(
          `<button role="button" type="button" class="spi-slide-close" aria-label="close"><i class="fa fa-times"></i></button>`
        );
    });

    jQuery(document).on(
      "click touch",
      ".spi-backdrop, .spi-slide-close",
      function () {
        jQuery(".flexslider")
          .removeClass("spi-show")
          .find(".spi-backdrop, .spi-slide-close")
          .remove();
      }
    );
  },

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
